@import "src/assets/css/colors.scss";

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 1.2222222222rem;
  width: 1.2222222222rem;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 1.2222222222rem;
  width: 1.2222222222rem;
  min-width: 0;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li.slick-active button::before {
  color: #1a1a1a;
  opacity: 1;
}

.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\25cf";
  width: 1.2222222222rem;
  height: 1.2222222222rem;
  font-family: "SH Icon";
  font-size: 1.2222222222rem;
  line-height: 1;
  text-align: center;
  color: $black-30;
  opacity: .5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
